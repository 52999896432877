<template>
  <div>
    <div class="row justify-content-end mb-2">
      <b-col md="3">
        <b-form-datepicker
          placeholder="Choose a date"
          local="en"
          v-model="from_date"
        />
      </b-col>
      <div md="1" class="d-flex align-items-center">to</div>
      <b-col md="3">
        <b-form-datepicker
          placeholder="Choose a date"
          local="en"
          v-model="to_date"
          @input="getData()"
        />
      </b-col>
    </div>

    <div class="row match-height">
      <div class="col-sm-4 col-lg-4">
        <b-card>
          <b-row>
            <b-col md="7" class="text-center">
              <h4 class="mt-2 font-weight-bolder">Average Production</h4>
              <h2 class="mt-2" style="color: #38c06c">
                {{ info.avg_production_percentage }}%
              </h2>
              <!-- <vue-apex-charts
                type="donut"
                :options="chartOptions"
                :series="series"
                height="120"
              /> -->
            </b-col>
            <!-- chart -->

            <b-col>
              <div class="mb-2">
                <h6>Setter</h6>
                <h2 class="font-weight-bolder">
                  {{ info.total_egg_input || "NA" }}
                </h2>
              </div>
              <div>
                <h6>Production</h6>
                <h2 class="font-weight-bolder">
                  {{ info.total_chick_output || "NA" }}
                </h2>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div class="col-sm-2 col-lg-2">
        <div class="card">
          <!----><!---->
          <div class="card-body pb-0">
            <!----><!----><span
              class="b-avatar mb-1 badge-light-primary rounded-circle"
              style="width: 45px; height: 45px"
              ><span class="b-avatar-custom"
                ><b-img
                  :src="require('@/assets/images/svg/dashboard/Sale.svg')"
                  style="height: 24px; width: 24px" /></span
            ></span>
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ info.total_chick_sale }}
              </h2>
              <span>Total Sales</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-2 col-lg-2">
        <div class="card">
          <!----><!---->
          <div class="card-body pb-0">
            <!----><!----><span
              class="b-avatar mb-1 badge-light-info rounded-circle"
              style="width: 45px; height: 45px"
              ><span class="b-avatar-custom"
                ><b-img
                  :src="require('@/assets/images/svg/dashboard/purchase.svg')"
                  style="height: 24px; width: 24px" /></span
              ><!----></span
            >
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ info.total_egg_purchase }}
              </h2>
              <span>Total Purchased</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-2 col-lg-2">
        <div class="card">
          <!----><!---->
          <div class="card-body pb-0">
            <!----><!----><span
              class="b-avatar mb-1 badge-light-danger rounded-circle"
              style="width: 45px; height: 45px"
              ><span class="b-avatar-custom"
                ><b-img
                  :src="
                    require('@/assets/images/svg/dashboard/hatchability.svg')
                  "
                  style="height: 24px; width: 24px" /></span
              ><!----></span
            >
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">{{ info.hatch_ability }}</h2>
              <span>Total Hatchability(%)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-2 col-lg-2">
        <div class="card">
          <!----><!---->
          <div class="card-body pb-0">
            <!----><!----><span
              class="b-avatar mb-1 badge-light-warning rounded-circle"
              style="width: 45px; height: 45px"
              ><span class="b-avatar-custom"
                ><b-img
                  :src="require('@/assets/images/svg/dashboard/Motality.svg')"
                  style="height: 24px; width: 24px" /></span
              ><!----></span
            >
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">{{ info.mortality }}</h2>
              <span>Mortality(%)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <b-col md="6">
        <ChartjsLineChart />
      </b-col>
      <b-col md="6">
        <ChartjsBarChart />
      </b-col>
    </b-row>
    <b-card>
      <div class="d-flex justify-content-center m-1">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          img-width="100"
          img-height="100"
          controls
          indicators
          style="text-shadow: none"
          @sliding-start="onSlideStart(slide)"
          @sliding-end="onSlideEnd(slide)"
        >
          <b-carousel-slide
            class="item"
            v-for="item in carouselItems"
            :key="item.id"
            :img-src="item.sliders.url"
          ></b-carousel-slide>
        </b-carousel>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BCarousel,
  BCarouselSlide,
  BButton,
  BImg,
  BFormDatepicker,
} from "bootstrap-vue";

import ChartjsLineChart from "../../charts-and-maps/charts/chartjs/ChartjsLineChart.vue";
import ApexDonutChart from "../../charts-and-maps/charts/apex-chart/ApexBarCircleChart.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import ChartjsBarChart from "../../charts-and-maps/charts/chartjs/ChartjsBarChart.vue";
import axiosIns from "@/libs/axios";
import vmson from "@/libs/empty";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
    ChartjsLineChart,
    EcommerceOrderChart,
    EcommerceProfitChart,
    ChartjsBarChart,
    BCarousel,
    BCarouselSlide,

    BImg,
    VueApexCharts,
    BFormDatepicker,
  },
  data() {
    return {
      from_date: null,
      to_date: null,
      type: Array,
      slide: null,
      sliding: null,
      info: {},
      fromDate: null,
      toDate: null,
      carouselItems: [],
      series: [44],
    };
  },
  created() {
    this.fromDate = moment().clone().startOf("month").format("YYYY-MM-DD");
    this.toDate = moment().clone().endOf("month").format("YYYY-MM-DD");
    this.hatcheryId = this.$route.params.hatcheryId;
    this.getData();
  },
  methods: {
    getData(id) {
      let url = "";
      let hatcheryId = null;
      if (id) {
        hatcheryId = id;
      } else if (this.hatcheryId) {
        hatcheryId = this.hatcheryId;
      }

      if (hatcheryId) {
        if (this.from_date != null && this.to_date != null) {
          url = `web/hatcheries/${hatcheryId}/dashboard?from_date=${this.from_date}&to_date=${this.to_date}`;
        } else {
          url = `web/hatcheries/${hatcheryId}/dashboard?from_date=${this.fromDate}&to_date=${this.toDate}`;
        }
      } else {
        if (this.from_date != null && this.to_date != null) {
          url = `web/dashboard?from_date=${this.from_date}&to_date=${this.to_date}`;
        } else {
          url = `web/dashboard?from_date=${this.fromDate}&to_date=${this.toDate}`;
        }
      }

      axiosIns
        .get(url)
        .then((response) => {
          this.info = response.data;
          this.carouselItems = response.data.sliderList;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
  mounted() {
    vmson.$on("hatcheryIdEvent", (id) => {
    
    });
  },
};
</script>
<style lang="scss">
.carousel-item {
  background: transparent !important;
}
.carousel-item img {
  height: 200px !important ;
}
.carousel-indicators li {
  display: none;
}
.item {
  background: transparent;
  height: 200px;
  width: 600px !important;
  object-fit: contain !important;
  margin-bottom: 1rem;
}
.item.active {
  background: transparent !important;
}
.carousel-control-prev,
.carousel-control-next {
  display: none;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/hatchery.scss";
@import "@core/scss/vue/pages/_common.scss";
</style>
