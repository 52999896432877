<template>
	<b-card no-body>
		<b-card-header>
			<b-card-title>Hatchability(%)</b-card-title>

			<div class="mr-2">
				<div class="d-flex">
					<div class="d-flex align-items-center mr-1 font-weight-bold">Year</div>
					<v-select
						v-model="selected"
						label="option"
						:options="option"
						style="width: 120px"
						@input="getSelectedYear"
					></v-select>
				</div>
			</div>
		</b-card-header>

		<!-- chart -->
		<b-card-body>
			<vue-apex-charts type="bar" height="350" :options="chartOptions" :series="series" />
		</b-card-body>
	</b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import vmson from "@/libs/empty";

export default {
	components: {
		BCard,
		BCardHeader,
		BCardBody,
		BCardTitle,
		flatPickr,
		vSelect,
		VueApexCharts,
	},
	data() {
		const currentYear = new Date().getFullYear();
		const pastYears = 10; // Adjust the number of past years you want to include
		const options = [];

		// Generating options for current year and past years
		for (let i = 0; i < pastYears; i++) {
			options.push(currentYear - i);
		}
		return {
			selected: currentYear, // Default selection set to current year
			option: options,
			series: [
				{
					data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
				},
			],
			chartOptions: {
				chart: {
					type: "bar",
					height: 350,
				},
				plotOptions: {
					bar: {
						borderRadius: 4,
						horizontal: false,
					},
				},
				dataLabels: {
					enabled: false,
				},
				xaxis: {
					categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
					// title: {
					//   text: "Month",
					// },
				},
			},
		};
	},
	created() {
		this.getData();
	},
	mounted() {
		vmson.$on("hatcheryIdEvent", (id) => {
			this.getData(id);
		});
	},
	methods: {
		getSelectedYear(event) {
			this.selected = event;
			this.getData();
		},
		getData(id) {
			let hatcheryId = id;
			let url = "";
			if (hatcheryId === undefined) {
				hatcheryId = this.$route.params.hatcheryId;
			} else {
				hatcheryId = id;
			}
			if (hatcheryId) {
				url = `web/dashboard-hatchability?year=${this.selected}&hatcheryId=${hatcheryId}`;
			} else {
				url = `web/dashboard-hatchability?year=${this.selected}`;
			}
			//   var hatcheryId = this.$route.params.hatcheryId;
			axiosIns.get(url).then((response) => {
				let data = response.data.map((item) => {
					return item.mortality_percentage.toFixed();
				});

				this.series = [
					{
						data: data,
					},
				];
			});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
