<template>
	<div style="height: 80px">
		<vue-apex-charts
			type="radialBar"
			height="160"
			:options="apexChatData.radialBarChart.chartOptions"
			:series="apexChatData.radialBarChart.series"
		/>
	</div>
</template>

<script>
import { BCard, BCardTitle, BCardSubTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import apexChatData from "./apexChartData";
import axiosIns from "@/libs/axios";
export default {
	components: {
		VueApexCharts,
		BCard,
		BCardTitle,
		BCardSubTitle,
	},
	data() {
		return {
			apexChatData,
		};
	},
	
};
</script>
